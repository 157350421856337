import { render, staticRenderFns } from "./ItemsLibrary.vue?vue&type=template&id=b19f18da&scoped=true&"
import script from "./ItemsLibrary.vue?vue&type=script&lang=js&"
export * from "./ItemsLibrary.vue?vue&type=script&lang=js&"
import style0 from "./ItemsLibrary.vue?vue&type=style&index=0&id=b19f18da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b19f18da",
  null
  
)

export default component.exports